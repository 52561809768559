<template>
  <main>
    <div class="container">
      <div
        class="col-sm-8 mx-auto py-5 mt-5 justify-content-center"
        style="min-height: 60vh"
      >
        <div class="message-box text- p-4 rounded-lg shadow-mdn py-5">
          <div class="row" v-if="estPeuOuPasInstruit">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-">Quelle est la prochaine étape?</h4>
                <div class="text-muted mb-4">
                  Vous venez de terminer la première étape. Vous pouvez à présent prendre
                  rendez-vous avec un agent à la mairie ou à l’antenne de l’ANPE pour
                  finaliser votre enregistrement. Ce sera l’occasion de connaître les
                  différentes possibilités qui vous sont offertes par le Gouvernement pour
                  vous aider à réussir votre insertion dans l’emploi. <br /><br />

                  Pour obtenir un rendez-vous et finaliser votre enregistrement dans la
                  base de l’ANPE, veuillez cliquer sur le bouton ci-dessous. Veuillez-vous
                  munir d’un document d’identité, si vous en avez. <br />

                  <div>
                    Si vous avez des préoccupations, nous vous invitons à contacter les
                    numéros suivants :

                    <div>(gratuit) : <span class="fw-bold">+229 5110 3333</span></div>
                    <div>(payant) : <span class="fw-bold">+229 5110 6666</span></div>
                    <div>Nous sommes à votre service !</div>
                  </div>
                  <!-- <div class="mt-4">Pour obtenir un rendez-vous d'entretien, cliquez sur le bouton ci-après.</div> -->
                </div>
                <h6 v-if="hasValidEmail" class="text-muted mb-4">
                  Un lien vous a été envoyé par courriel pour la validation de votre
                  compte.
                </h6>
                <a href="/" class="btn btn-primary">Retourner à la page d'acceuil</a>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-sm-12">
              <div class="text-center mb-4">
                <h4 class="text-">Félicitations !</h4>
                <div class="text-muted mb-4">
                  <div class="mb-4">
                    Vous venez de vous inscrire avec succès. Un lien vous a été envoyé par
                    courriel à l’adresse électronique vous avez indiquée, afin de vous
                    permettre de finaliser votre dossier et de valider votre compte auprès
                    de l’ANPE.
                  </div>

                  <div>
                    Par ailleurs, nous serons ravis de vous recevoir à l’antenne de l’ANPE
                    ou au niveau de l’Unité Locale de Promotion de l’Emploi (ULPE) à la
                    mairie pour un entretien une fois que vous aurez finalisé votre
                    enregistrement. Ce sera l’occasion de mieux apprécier vos besoins et
                    de vous présenter les différents programmes que le Gouvernement met à
                    votre disposition pour vous aider à réussir votre insertion dans
                    l’emploi. Un agent vous contactera à cet effet.
                  </div>

                  <div>
                    Si vous avez des préoccupations, nous vous invitons à contacter les
                    numéros suivants :

                    <div>(gratuit) : <span class="fw-bold">+229 5110 3333</span></div>
                    <div>(payant) : <span class="fw-bold">+229 5110 6666</span></div>
                    <p class="mt-2">
                      L'ANPE, au service de l'emploi et des compétences !
                    </p>
                  </div>
                  <!-- <div class="mt-4">Pour obtenir un rendez-vous d'entretien, cliquez sur le bouton ci-après.</div> -->
                </div>
                <h6 v-if="hasValidEmail" class="text-muted mb-4">
                  Un lien vous a été envoyé par courriel pour la validation de votre
                  compte.
                </h6>
                <a href="/" class="btn btn-primary">Retourner à la page d'acceuil</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    estPeuOuPasInstruit() {
      return this.$route.query.ppi;
    },
    hasValidEmail() {
      return this.$route.hasEmail;
    },
  },
};
</script>

<style>
.message-box {
  background: #ffc10745 !important;
}
</style>
